/* eslint-disable max-len */
import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import swal from 'sweetalert';
import { filtersData, categoriesData } from "components/board/toolbox/actions/Actions.lib.categories"
import View from "components/board/toolbox/actions/Actions.view";
import { Loading } from "seed/helpers";


function Actions({
  toolboxRef,
  flowId,
  nodes,
  reqApps,
  reqAllApps,
  currentNodeContext,
  isCollapsed,
  isAnonymous,
  isDisabled,
  onClickNode
}) {

  const userId = sessionStorage.getItem('id');
  const prevNodesLength = useRef(nodes.length);
  const [isFilterSectionShown, setFilterSectionShown] = useState(false);
  const [actionSearch, setActionSearch] = useState("");
  const [actionFilters, setActionFilters] = useState([]);
  const [appSettingses, setAppSettingses] = useState([]);
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  const actualNodeId = currentNodeContext?.node_id;
  const position = currentNodeContext?.position ?? "BOTTOM";
  const mode = currentNodeContext?.mode ?? "NEXT";

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
    }
  }`, userId);

  const reqAppSettings = useQuery(`{
    appSettingses {                
      enabled
      requested
      flow { }
      app { name }
    }
  }`, `flow.id=${flowId}`, {
    onCompleted: (data) => {
      setAppSettingses(data?.appSettingses ?? []);
    }
  });

  const reqFlow = useDetail(`{ flow { name } }`, flowId)

  const [callSetEnabled, qSetEnabled] = usePost('/apps/set_status_enabled', {
    onCompleted: (data) => {
      if (data.status == true) {
        swal("Acciones instaladas", "Módulo agregado correctamente", "success").then(() => {
          reqApps.refetch();
          reqAllApps.refetch();
          reqFlow.refetch();
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });


  useEffect(() => {
    if (nodes.length != prevNodesLength.current)
      prevNodesLength.current = nodes.length;
  }, [])

  const onDragStart = (event, type, actionId) => {
    const data = {
      "type": type,
      "actionId": actionId
    };
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onClickAction = (actionId, flipMode = false) => {
    const args = {
      actionId: actionId,
      flipMode: flipMode
    }
    if (actualNodeId != null && actualNodeId != undefined) {
      args.parentId = actualNodeId;
      args.position = position;
      args.mode = mode;
    }
    onClickNode(args);
  };

  const onSearchActions = (search) => {
    setActionSearch(search)
    setActionFilters([])
    const categoryButtons = document.querySelector(".btn-category")
    if (categoryButtons != null)
      categoryButtons.setAttribute("aria-expanded", false)
  }

  const onClickSearchRemove = () => {
    setActionSearch("")
    setActionFilters([])
    document.querySelector(".search-input").focus()
  }

  const onClickSearchFilter = () => {
    if (isFilterSectionShown)
      setActionFilters([])
    setFilterSectionShown(!isFilterSectionShown)
  }

  const onClickFilterTab = (value) => {

    const filterIndex = actionFilters.indexOf(value)
    if (filterIndex == -1)
      setActionFilters([value])
    else onClickFilterRemove(value)
    setActionSearch("")
    setFilterSectionShown(filterIndex == -1)
  }

  const onClickFilterRemove = (value) => {
    const filterIndex = actionFilters.indexOf(value)
    if (filterIndex != -1) {
      let tempVal = [...actionFilters]
      tempVal.splice(filterIndex, 1)
      setActionFilters(tempVal)
      setFilterSectionShown(false)
    }
  }


  const onEnableApp = (app_id) => {
    const appSettings = appSettingses.find(setting => setting?.flow?.id === parseInt(flowId) && setting?.app?.id == app_id);
    const settingsId = appSettings ? appSettings.id : 0;
    reqAppSettings.refetch();
    callSetEnabled({ app_id: app_id, flow_id: flowId, settings_id: settingsId });
  }


  // Export functions to be used by parent component
  useImperativeHandle(toolboxRef, () => ({
    onClickFilterTab
  }));

  if (reqApps.loading || reqAllApps.loading || reqFlow.loading || reqUser.loading || reqAppSettings.loading) return <PlaceholderView />;
  if (reqApps.error || reqAllApps.error || reqFlow.error || reqUser.error || reqAppSettings.error) return "Error";

  let flow = reqFlow.data.flow
  let user = reqUser.data.user;

  // Merge apps and appsettings
  const mergeAppSettings = (appsToMerge) => {
    let apps = []
    for (let app of appsToMerge){
      let currentApp = app
      for (let appSettings of appSettingses)
        if (appSettings.app.id == app.id)
          currentApp = {...currentApp, ...{appSettings: appSettings}
        }
      apps.push(currentApp)
    }
    return apps
  }
  
  let allApps = mergeAppSettings(reqAllApps.data.apps)
  let apps = mergeAppSettings(reqApps.data.apps)

  return (
    <View
      apps={apps}
      allApps={allApps}
      flow={flow}
      user={user}
      filtersData={filtersData}
      categoriesData={categoriesData}
      actionSearch={actionSearch}
      actionFilters={actionFilters}
      isCollapsed={isCollapsed}
      isDisabled={isDisabled}
      isAnonymous={isAnonymous}
      isDeveloper={isDeveloper}
      isFilterSectionShown={isFilterSectionShown}
      reqApps={reqApps}
      onEnableApp={onEnableApp}
      onSearchActions={onSearchActions}
      onClickSearchRemove={onClickSearchRemove}
      onClickSearchFilter={onClickSearchFilter}
      onDragStart={onDragStart}
      onClickAction={onClickAction}
      onClickFilterTab={onClickFilterTab}
      onClickFilterRemove={onClickFilterRemove}
    />
  );

}

const PlaceholderView = () =>
  <div className="h-100">
    <div id="toolbox-actions">
      <Loading color="#176DC7"/>
    </div>
  </div>

export default Actions;