import React from "react";
import { Formik, Form, Field } from 'formik';
import { TwitterPicker } from 'react-color';
import { FileField } from "seed/helpers";
import { StyleTag } from "seed/helpers";


const CustomizationView = ({ user, onSubmit }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Personalización</h3>
    </div>
    <div className="card-body animate__animated animate__fadeIn animate__faster">

      <Formik
        initialValues={{
          showHelps: user.accountSettings.showHelps,
          primaryColor: user.accountSettings.theme?.primaryColor,
          accentColor: user.accountSettings.theme?.accentColor,
          logo: user.accountSettings.logo,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div class="form-group mb-4">
              <div class="custom-control custom-checkbox">
                <Field type="checkbox" name="showHelps" id="showHelps"
                  class="custom-control-input" />
                <label class="custom-control-label" for="showHelps">
                  Mostrar mensajes de ayuda
                </label>
              </div>
            </div>

            <ColorPicker
              label="Color principal"
              name="primaryColor"
              values={values}
              setFieldValue={setFieldValue}
            />

            <ColorPicker
              label="Color de acento"
              name="accentColor"
              values={values}
              setFieldValue={setFieldValue}
            />

            <div className="d-flex mt-3">
              {values?.logo && (
                <div className="mr-3" style={{ maxWidth: "150px" }}>
                  Logo actual
                  <img src={values.logo} className="img-fluid" alt="Preview custom Logo"/>
                </div>
              )}
              <div className="form-group">
                <label className="mb-2">Logo personalizado</label>
                <FileField
                  name="logo"
                  id="logo"
                  required={true}
                  accept=".jpg,.jpeg,.svg,.png,.gif,.bmp,.webp"
                  class="form-control form-control-lg"
                  setFieldValue={() => { }}
                  onCompleted={(data) => setFieldValue("logo", data?.url)}
                />
              </div>
            </div>

            <div className="text-right">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  </div>
);

const ColorPicker = ({ label, name, values, setFieldValue, onChange = () => { } }) => {
  const colors = [
    "#FF5252", "#FF4081", "#7B1FA2", "#512DA8", "#536DFE", "#2080e5", "#3075BA", "#1976D2", "#03A9F4", "#0097A7", "#00796B", "#4CAF50", "#AFB42B",
    "#F57C00", "#FFA000", "#FF5722", "#5D4037", "#9E9E9E", "#455A64", "#212121", "default"
  ];
  return <div className="d-flex align-items-center w-100 mb-3">
    <StyleTag content={`
      .twitter-picker input[id^=rc-editable-input] {
        display: none;
      }
      .twitter-picker div[style*="rgb(240, 240, 240)"] {
        display: none !important;
      }
    `} />
    <div className="mb-1 mr-2" style={{ width: "100px" }}>
      {label}
    </div>
    <div style={{ position: "relative" }}>
      <div className="color-picker-preview" onClick={() => setFieldValue(`show_${name}`, !values[`show_${name}`])}>
        <div className="color" style={{ backgroundColor: values[name] }}></div>
      </div>
      <div style={{ position: "absolute", left: "110%", top: "-13px", zIndex: 1000 }}>
        {
          values[`show_${name}`] &&
          <TwitterPicker
            color={values[name]}
            width="240px"
            triangle="hide"
            onChange={(color) => {
              setFieldValue(name, color.hex);
              setFieldValue(`show_${name}`, false);
              onChange(color.hex);
            }}
            colors={colors}
          />
        }
      </div>
    </div>
  </div>
}


export default CustomizationView;