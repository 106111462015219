import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal, ModalRoute } from "seed/helpers";
import { Link } from "react-router-dom";
import Settings from "components/presentation/Settings";
import HelpMessage from 'components/util/helpers/HelpMessage';
import StyleColor from "components/util/helpers/StyleColor"
import Collaboration from "components/studio/forms/collaboration/Collaboration";
import Forms from "components/tmisc/apps/forms/Forms";
import ExecMode from "components/presentation/ExecMode";
import Execution from "components/studio/forms/executions/Execution";
import WSManager from "components/util/ws";
import { APP_URL, LANDING_URL } from "settings";

import "styles/css/presentation/Presentation.css";

const PresentationView = ({
  user,
  flow,
  roomName,
  actualExecutionId,
  isEditor,
  isExecutionDetailsShown,
  execModeRef,
  setIsExecutionDetailsShown,
  onExecutionMessage,
}) => (
  <BrowserRouter basename={`/p/${flow.token}`}>
    <div className="content container-fluid px-0 px-lg-3 px-xl-4 px-xxl-6 py-0 py-lg-3 py-xl-4 py-xxl-6"
      style={{ height: "100vh" }}>
      <div className="d-flex justify-content-center h-100">
        <div id="presentation-mode" className="card p-0">
          <div className="w-100 h-100">

            <div id="presentation-header"
              className={"d-flex justify-content-between align-items-center " + (!isEditor ? "read-mode" : "")}>

              <div class="d-flex align-items-center" id="presentation-title">

                <a
                  className="back-button d-flex align-items-center justify-content-center"
                  title="Regresar"
                  href={isEditor ? `${APP_URL}/app/flow/${flow.id}` : LANDING_URL}
                  onClick={() => { }}>
                  <i class="fas fa-arrow-left d-block"></i>
                </a>

                <div class="w-auto ml-1">
                  <a href={isEditor ? `${APP_URL}/app/flow/${flow.id}` : LANDING_URL} >
                    {user?.accountSettings?.logo ? (
                      <img src={user?.accountSettings?.logo} className="logo" alt="Custom logo"/>
                    ) : (
                      <div className="logo-workix"></div>
                    )}
                  </a>
                </div>


              </div>

              <div id="presentation-actions" className="d-flex">

                <span className="d-flex align-items-center">

                  <HelpMessage message="Comparte tu proceso con otras personas, permitiendo a otros colaborar"
                    style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                    <Link to="/share" className="btn btn-sm btn-outline-primary mr-2 share-button">
                      <i class="fas fa-share mr-1"></i> Compartir
                    </Link>
                  </HelpMessage>

                  <HelpMessage message="Visualiza tu proceso de forma gráfica"
                    style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                    <Link to="/settings" className="btn btn-sm btn-primary mr-1 px-3">
                      <i class="fas fa-cog mr-1"></i> Configurar
                    </Link>
                  </HelpMessage>

                </span>

              </div>
            </div>

            <div style={{ height: "calc(100% - 70px)" }}>
              <ExecMode
                ref={execModeRef}
                flow={flow}
                isShareMode={!isEditor}
              />
            </div>

          </div>
        </div>
      </div>

      <StyleColor user={user} />

      {isEditor ?
        <ModalRoute
          width={1000}
          height={window.innerHeight - 150}
          animation={""}
          path="/settings"
          flow={flow}
          execModeRef={execModeRef}
          component={Settings}
          isEditor={isEditor}
        /> : null}

      {isEditor ?
        <ModalRoute
          width={650}
          height={window.innerHeight - 150}
          animation={""}
          path="/share"
          component={() => <Collaboration flowId={flow.id} />}
        /> : null}

      {isExecutionDetailsShown && (
        <Modal
          width={800}
          height={800}
          animation={""}
          executionId={actualExecutionId}
          isShown={true}
          isButtonHidden={true}
          onClose={() => setIsExecutionDetailsShown(false)}
          component={Execution}
        />
      )}

      <WSManager
        initialRoom={roomName}
        autoSuscribe={true}
        onMessageReceived={onExecutionMessage}
      />

      <Forms flow={flow} />
    </div>
  </BrowserRouter>
)

export default PresentationView;