import React from "react";
import View from "components/util/schema_form/fields/dashboard/Chart.view";


function Chart({ chart, isSelected, onSelectChart, onDeleteChart }) {

  const id = chart.id;
  const type = chart.type;
  const title = chart.title;

  const onClickChart = () => {
    onSelectChart(id);
  }

  const onClickDeleteChart = () => {
    onDeleteChart(id);
  }

  return (
    <View
      id={id}
      type={type}
      title={title}
      isSelected={isSelected}
      onClickChart={onClickChart}
      onClickDeleteChart={onClickDeleteChart}
    />
  );
}


export default Chart;