import React from "react";
import Executions from "components/executions/Executions";
import "styles/css/board/Executions.css";

const ExecutionsView = ({ flow, isExecutionsCollapsed, onClickCloseExecutions }) => (
  
  <div id="executions-board" className={`h-100 ${isExecutionsCollapsed ? "collapsed" : ""}`}>
    {!isExecutionsCollapsed ? (
      <>
        <div id="executions-board-close" onClick={onClickCloseExecutions}>
          <i class="fas fa-angle-right"></i>
        </div>

        <Executions
          flow={flow}
          styles={{ height: "100%" }}
          title="Ejecuciones"
        />
      </>
    ) : null}
  </div>
);


export default ExecutionsView;