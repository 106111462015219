import React, { useState } from "react";
import { useEdges, useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import { bodyContentMapper } from "components/board/nodes/Node.lib";
import * as lib from "components/board/nodes/Node.lib";
import * as executionLib from "components/board/nodes/Node.lib.execution";
import View from "components/board/nodes/Node.view";


function Node({ id, data }) {

  const nodes = useNodes();
  const edges = useEdges();
  const remoteId = data?.remoteId;
  const nodeInput = data.input_values;
  const nodeOutput = data.output_values;
  const action = data?.action??{};
  const flowId = data?.flowId;
  const isIterable = data?.is_iterable ?? false;
  const isSelected = data?.is_selected ?? false;
  const currentExecution = data?.currentExecution;
  const onExecute = data.onExecute;
  const onInsertChild = data.onClickInsertChild;
  const warnings = data?.warnings ?? [];
  const errors = data?.errors ?? [];
  const status = data?.status;
  const nodeIdx = data?.idx;
  const app = action?.app ?? {};
  const isEditable = action?.isEditable;
  const insertChildAttrs = data?.insert_child_attrs;

  const isPreview = data?.is_preview ?? false;
  const displayNodeOptions = action.uiSettings?.node ?? [];
  const formSettings = action.uiSettings.form;
  const placeholder = bodyContentMapper(displayNodeOptions, nodeInput, "execution_message", formSettings)

  let initialExecutionMessage = null;
  let initialExecutionPlaceholder = null;
  if (placeholder == "" && action.type == "TRIGGER") {
    initialExecutionPlaceholder = "Evento";
    initialExecutionMessage = "Evento";
  } else {
    initialExecutionPlaceholder = placeholder;
    initialExecutionMessage = placeholder;
  }

  const isRoot = lib.checkRoot(id, nodes, edges);
  const historyRouter = useHistory();
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  const { setNodes } = useReactFlow();
  const [canNodeBeExecuted, setCanNodeBeExecuted] = useState(true);

  const [executionMessage, setExecutionMessage] = useState(initialExecutionMessage);
  const [executionPlaceholder, setExecutionPlaceholder] = useState(initialExecutionPlaceholder);

  const onClickExecute = (e) => {
    const [canExecute, message] = executionLib.canExecuteNode(nodes, edges, remoteId, flowId)
    setCanNodeBeExecuted(canExecute)
    if (message == "") setExecutionMessage(executionPlaceholder)
    else setExecutionMessage(message)

    if (canExecute && action?.type != "TRIGGER") {
      e.stopPropagation();
      onExecute();
    }
  }

  const onChangeStatusTrigger = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_enabled = !actualNode.data.is_enabled;
    setNodes(nodes);
  }

  const onSelectNode = () => {
    setNodes((nodes) => {
      let actualNode = nodes.find((nd) => nd.id == id);
      if (actualNode.data.is_selected == null) {
        actualNode.data.is_selected = true;
      } else {
        actualNode.data.is_selected = !actualNode.data.is_selected;
      }
      return nodes;
    });
    data.onSelectNode()
  }

  const onEdit = () => {
    if (!isEditable) return;
    const node = nodes.find((nd) => nd.id == id);
    if (node) {
      const url = `/flow/edit/${node.remoteId}`
      historyRouter.replace(url);
    }
  }

  return (
    <View
      app={app}
      action={action}
      nodeId={id}
      nodeIdx={nodeIdx}
      nodeInput={nodeInput}
      nodeOutput={nodeOutput}
      nodeData={data}
      errors={errors}
      warnings={warnings}
      status={status}
      currentExecution={currentExecution}
      executionMessage={executionMessage}
      insertChildAttrs={insertChildAttrs}
      canNodeBeExecuted={canNodeBeExecuted}
      isRoot={isRoot}
      isIterable={isIterable}
      isSelected={isSelected}
      isEditable={isEditable}
      isDeveloper={isDeveloper}
      isPreview={isPreview}
      onClickExecute={onClickExecute}
      onEdit={onEdit}
      onDeleteNode={data.onDeleteNode}
      onSelectNode={onSelectNode}
      onInsertChild={onInsertChild}
      onChangeStatusTrigger={onChangeStatusTrigger}
    />
  );
}

export default Node;