import React, { useState, useEffect, useRef } from "react";
import { SET_EXECUTION } from "seed/gql/queries";
import { useQuery, useDetail, useSet } from "seed/gql";
import { useGetCall } from "seed/api";
import { Loading } from "seed/helpers";
import { showExecutionToast } from "components/util/flow";
import View from "components/presentation/Presentation.view";
import ChatView from "components/presentation/Presentation.view"

function Presentation({ match }) {

  const token = match.params?.token;
  const userId = sessionStorage.getItem("id");
  const execModeRef = useRef(null);
  const [isExecutionDetailsShown, setIsExecutionDetailsShown] = useState(false);
  const [actualExecutionId, setActualExecutionId] = useState(null);

  const [isAuth, setIsAuth] = useState(false);

  const [callSetRead] = useSet(SET_EXECUTION);

  const reqFlow = useQuery(`{
    flows {
      name
      description
      token
      presentationSettings
      settings
      participants {}
      user {}
    }
  }`, `token=${token}`);

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
      accountSettings
    }
  }`, userId);

  const [callAuth] = useGetCall("/auth/user", "", {
    onCompleted: () => {
      setIsAuth(true)
      reqFlow.refetch();
      reqUser.refetch();
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      postMessage({ type: "logout" });
      window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    }
  })

  const { user = null } = reqUser.data || {};
  const { flows = [] } = reqFlow.data;

  const roomName = `EXECUTION_STATUS_${flows[0]?.id}`;

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
    callAuth({ user_id: sessionStorage.getItem("id") });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!user) return console.log("No user data");
    sessionStorage.setItem("show_helps", user?.account_settings?.showHelps);

    const currentTheme = sessionStorage.getItem("theme") ?? "{}";
    const theme = JSON.stringify(user?.account_settings?.theme ?? {});
    if (currentTheme != theme) {
      sessionStorage.setItem("theme", theme);
      window.location.reload();
    }
  }, [flow]);

  if (!isAuth) return null;
  if (reqFlow.loading || reqUser.loading) return <Loading />;
  if (reqFlow.error || reqUser.error) return "Error";
  if (flows.length == 0) window.location.replace("/app");
  const flow = flows[0];

  const onExecutionMessage = (data) => {

    const status = data.data.status;
    const executionId = data.data.execution_id;
    const executionNodeId = data.data.node_id;
    const lastNode = data.data.last_node;
    const message = data.data.message;
    const awaitingAlert = data.data.awaiting_alert;

    callSetRead({ id: executionId, isRead: true });
    showExecutionToast({
      status, executionId, executionNodeId, lastNode, message,
      awaitingAlert, setActualExecutionId, setIsExecutionDetailsShown
    });

  }

  const isEditor = 
    flow.participants.map(participant => participant.id).includes(userId) || flow.user.id == userId

  return (
    <ChatView
      user={user}
      flow={flow}
      roomName={roomName}
      execModeRef={execModeRef}
      actualExecutionId={actualExecutionId}
      isEditor={isEditor}
      isExecutionDetailsShown={isExecutionDetailsShown}
      setIsExecutionDetailsShown={setIsExecutionDetailsShown}
      onExecutionMessage={onExecutionMessage}
    />);
}

export default Presentation;