import React, {useState, useRef

} from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { getMention } from "components/util/schema_form/fields/rich_text/Editor.lib";

import { assignIdxNodes } from "components/studio/Controller.lib.index"
import * as parser from "components/studio/Controller.lib.parser";
import View from "components/util/schema_form/fields/rich_text/RichText.view";

function RichText({
  name,
  label,
  value = "",
  values,
  required,
  rows = 5,
  nodeId,
  caption,
  helpMessage,
  placeholder,
  singleLine,
  showStyles,
  flowId,
  setFieldValue
}) {
  const pageId = sessionStorage.getItem("page_" + flowId)
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [commandEditor, setCommandEditor] = useState(() => {});
  const editorRef = useRef(null);
  
  
  const reqNodes = useQuery(`{
    flowNodes {
      createdAt
      inputValues
      outputValues
      uiSettings
      isEnabled
      action { 
        name
        type
        isEditable
        inputStructure
        outputStructure
        uiSettings
        isAnnotation
        app { 
          name
          uiSettings
        }
      }
      isVerified
      isIterable
      iterableField
      flow { name }
    }
  }`, `flow.id=${flowId} ${pageId ? "AND page.id=" + pageId : ""}`)

  const reqEdges = useQuery(`{
    flowEdges {
      type
      createdAt
      sourceName
      targetName
      source { }
      target { }
      flow { name }
    }
  }`, `flow.id=${flowId} ${pageId ? "AND page.id=" + pageId : ""}`);

  const reqCollections = useQuery(`{
    collections {
      name
      schema
    }
  }`, `flow.id=${flowId}`)

  const reqDataTypes = useQuery(`{
    appDatatypes {
      name
      structure
    }
  }`, '')

  if (reqNodes.loading || reqCollections.loading || reqDataTypes.loading || reqEdges.loading) return <Loading size={27} />;
  if (reqNodes.error || reqCollections.error || reqDataTypes.error || reqEdges.error) return "Error";

  const nodes = [];
  const { flowNodes = [] } = reqNodes.data;
  const { flowEdges = [] } = reqEdges.data;
  const { collections = [] } = reqCollections.data;
  const { datatypes = [] } = reqDataTypes.data;

  // Assign IDX to nodes
  const parsedNodes = parser.parseNodesDatabaseToUi(flowNodes);
  const parsedEdges = parser.parseEdgesDatabaseToUi(flowEdges);
  const nodesWithIdx = assignIdxNodes(parsedNodes, parsedEdges);

  for (const node of nodesWithIdx) {
    let currentNode = { ...flowNodes.find(nd => nd.id == node.data.remoteId) };
    if (currentNode && currentNode.action.outputStructure.length > 0 && currentNode.id != nodeId) {
      currentNode.idx = node.data.idx
      nodes.push(currentNode)
    }
  }

  const onChangeValue = (newValue) => {
    setFieldValue(name, newValue)
  }

  const onClickAddReference = (command) => {
    setCommandEditor(() => command);
    setIsVariableModalOpen(true);
  }

  const onCloseAddReference = () =>
    setIsVariableModalOpen(false)

  const onVariableSelected = (value) => {
    const mention = getMention(value, nodes, collections, datatypes);   
    if (mention) {
      commandEditor(mention)
    }
    setIsVariableModalOpen(false)
  }

  return (
    <View
      editorRef={editorRef}
      nodes={nodes}
      collections={collections}
      datatypes={datatypes}
      name={name}
      label={label}
      required={required}
      caption={caption}
      helpMessage={helpMessage}
      placeholder={placeholder}
      singleLine={singleLine}
      rows={rows}
      showStyles={showStyles}
      values={values}
      flowId={flowId}
      nodeId={nodeId}
      initialValue={value}
      isVariableModalOpen={isVariableModalOpen}
      setFieldValue={setFieldValue}
      onChangeValue={onChangeValue}
      onVariableSelected={onVariableSelected}
      onClickAddReference={onClickAddReference}
      onCloseAddReference={onCloseAddReference}
    />
  );
}

export default RichText;