
import React from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Modal, ModalRoute } from "seed/helpers";
import * as Fields from "components/util/schema_form/fields";
import { FieldWrapper, MultipleFieldWrapper } from "components/presentation/Settings.sub.wrappers.view";
import TableSettings from "components/presentation/Settings.sub.table.view";
import FormSettings from "components/presentation/Settings.sub.form.view";

const SettingsView = ({
  formRef,
  flow,
  collections = [],
  execModeDefaults = [],
  execModeSettings = [],
  activeTab,
  isOptionRight,
  isEditor,
  setIsOptionRight,
  getPanelOptions,
  getColumnOptions,
  onCreateTable,
  onAddSection,
  onChangeTab,
  onDelete,
  onSubmit,
  onReset,
  onClose
}) => (
  <div className="card p-5">

    <div className="card-title d-flex justify-content-between mb-2">
      <h2>Configurar modo visual</h2>
    </div>

    <div className="card-body p-0">
      <Formik
        innerRef={formRef}
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="m-0 p-0">

            {/* Tabs */}
            <div className="d-flex mb-4 scrollbar-narrow"
              style={{overflowX: "auto"}}>

              {
                execModeSettings.map((execModeSetting, index) => (
                  <div
                    key={"tab_" + index}
                    className={`btn d-flex justify-content-center mr-3 exec-mode-tab 
                      ${activeTab === index ? "active" : ""}`}
                    style={{ maxWidth: "16rem" }}
                    onMouseEnter={() => setFieldValue("hoverTab", index)}
                    onMouseLeave={() => setFieldValue("hoverTab", null)}
                  >
                    <span
                      className="text-truncate py-2"
                      onClick={() => onChangeTab(values, index)}
                    >
                      {execModeSetting.title ?? "Nueva sección"}
                    </span>
                    {
                      values.hoverTab === index &&
                      <span>
                        <i className="fas fa-trash ml-2 mr-2" onClick={() => onDelete(index)}></i>
                      </span>
                    }
                  </div>
                ))
              }

              <button
                type="button"
                className={`btn btn-lighter text-center px-2 ml-3 mr-2`}
                style={{ minWidth: "9.5rem" }}
                onClick={() => onAddSection(values)}
              >
                <i className="fas fa-plus"></i> Agregar sección
              </button>

            </div>

            {
              function () {

                let currentDefaults = execModeDefaults[values.reference];
                if (!currentDefaults) currentDefaults = {};

                return <>

                  <div className="d-flex justify-content-between align-items-center mt-0 pt-0">
                    <h5 className="m-0 p-0">Datos generales</h5>
                    <button
                      type="button"
                      class="btn btn-link py-0"
                      onClick={onReset}
                    >
                      Restablecer default
                    </button>
                  </div>

                  {/* Fields */}
                  <div className="">
                    <ul className={"step step-form step-eicon-xs mb-0 mt-4"}>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.SelectField
                            required={true}
                            label={"Plantilla"}
                            name={`reference`}
                            setFieldValue={setFieldValue}
                            value={values.reference}
                            values={values}
                            onChange={(value) => {

                              let currentDefaults = execModeDefaults[value];
                              if (!currentDefaults) currentDefaults = { reference: {} };

                              setFieldValue(`reference`, value);
                              setFieldValue(`title`, currentDefaults.reference.title);
                              setFieldValue(`actions`, currentDefaults.actions);
                              setFieldValue(`tables`, currentDefaults.tables);
                              setFieldValue(`left_panel`, currentDefaults.left_panel);
                              setFieldValue(`right_panel`, currentDefaults.right_panel);

                            }}
                            options={
                              Object.keys(execModeDefaults).map(k =>
                                ({ label: execModeDefaults[k].reference.title, value: execModeDefaults[k].reference.id }))}
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.TextField
                            required={true}
                            label={"Título"}
                            name={"title"}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          {
                            values.title != currentDefaults.title &&
                            <button
                              type="button"
                              class="btn btn-link"
                              style={{ width: "140px" }}
                              onClick={() => {
                                setFieldValue("is_manual_title", false)
                                setFieldValue("title", currentDefaults.title)
                              }}
                            >
                              Reestablecer
                            </button>
                          }
                        </div>
                      </FieldWrapper>
                    </ul>
                  </div>

                  <h5 className="pt-2">Acciones</h5>

                  {/* Buttons */}
                  <div className="">
                    <MultipleFieldWrapper
                      title={"Acciones"}
                      isDefault={values.actions == currentDefaults.actions}
                      values={values}
                      valueKey={"actions"}
                      setFieldValue={setFieldValue}
                      onChangeDefaults={() => {
                        setFieldValue("is_manual_actions", true);
                        setFieldValue("actions", currentDefaults.actions);
                      }}
                    >
                      {
                        (values.actions ?? []).map((action, idx) => (
                          <>
                            <FieldWrapper>
                              <div className="d-flex justify-content-between align-items-end">
                                <Fields.SelectField
                                  required={true}
                                  label={"Referencia"}
                                  name={`actions[${idx}].id`}
                                  setFieldValue={setFieldValue}
                                  value={action.id}
                                  values={values}
                                  onChange={(value) => {
                                    let currentDefaults = execModeDefaults[value];
                                    if (!currentDefaults) currentDefaults = { reference: {} };
                                    setFieldValue(`actions[${idx}]`, currentDefaults.reference);
                                  }}
                                  options={
                                    Object.keys(execModeDefaults).map(k =>
                                      ({ label: execModeDefaults[k].title, value: k }))}
                                />
                              </div>
                            </FieldWrapper>
                            <FieldWrapper>
                              <div className="d-flex justify-content-between align-items-end">
                                <Fields.TextField
                                  required={true}
                                  label={"Texto"}
                                  name={`actions[${idx}].title`}
                                  value={values.actions[idx]?.title}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                                {
                                  values.actions[idx].title != execModeDefaults[values.actions[idx].id]?.reference.title &&
                                  <button
                                    type="button"
                                    class="btn btn-link"
                                    style={{ width: "140px" }}
                                    onClick={() => {
                                      setFieldValue(`actions[${idx}].title`, execModeDefaults[values.actions[idx].id]?.reference.title)
                                    }}
                                  >
                                    Reestablecer
                                  </button>
                                }
                              </div>
                            </FieldWrapper>
                            <FieldWrapper>
                              <div className="d-flex justify-content-between align-items-end">
                                <Fields.CheckboxField
                                  label={"Es acción principal"}
                                  name={`actions[${idx}].is_main`}
                                  value={values.actions[idx]?.is_main}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </FieldWrapper>
                          </>
                        ))
                      }
                    </MultipleFieldWrapper>
                  </div>

                  <h5 className="pb-3 pt-2">Secciones</h5>

                  {/* Panels */}
                  <div
                    className="row px-2 pt-0 mt-0"
                    style={{
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      className={"col-md-6"}
                    >
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.2rem" }}>Panel izquierdo</span>
                          </div>
                          <div className="d-flex justify-content-center text-left px-2 pt-1">
                            <Fields.SelectField
                              required={true}
                              label={"Componente"}
                              name={"left_panel"}
                              value={values.left_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              options={getPanelOptions(values, execModeDefaults)}
                            />

                          </div>

                          <div className="d-flex justify-content-between px-2" style={{ height: "25px" }}>

                            <button
                              type="button"
                              class="btn btn-link p-0 mt-2"
                              style={{ visibility: values.left_panel != currentDefaults.left_panel ? "visible" : "hidden" }}
                              onClick={() => {
                                setFieldValue("is_manual_left_panel", false)
                                setFieldValue("left_panel", currentDefaults.left_panel)
                              }}
                            >
                              Reestablecer
                            </button>

                            {
                              values.left_panel == "TABLE" &&
                                <Link 
                                  to="/settings/table"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => {
                                    setIsOptionRight(false);
                                  }}
                                >
                                  Opciones
                                </Link>
                            }
                            {
                              values.left_panel == "FORM" &&
                                <Link
                                  to="/settings/form"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => {
                                    setIsOptionRight(false);
                                  }}
                                >
                                  Opciones
                                </Link>
                            }
                          </div>

                        </div>
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                          {getPanelIcon(values.left_panel)}

                        </div>
                      </div>
                    </div>
                    <div className={"col-md-6"}>
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.2rem" }}>Panel derecho</span>
                          </div>
                          <div className="d-flex justify-content-center text-left px-2 pt-1">
                            <Fields.SelectField
                              required={true}
                              label={"Componente"}
                              name={"right_panel"}
                              value={values.right_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              options={getPanelOptions(values, execModeDefaults)}
                            />
                          </div>

                          <div className="d-flex justify-content-between px-2" style={{ height: "25px" }}>

                            <button
                              type="button"
                              class="btn btn-link p-0 mt-2"
                              style={{ visibility: values.left_panel != currentDefaults.left_panel ? "visible" : "hidden" }}
                              onClick={() => {
                                setFieldValue("is_manual_right_panel", false)
                                setFieldValue("right_panel", currentDefaults.right_panel)
                              }}
                            >
                              Reestablecer
                            </button>

                            {
                              values.right_panel == "TABLE" &&
                                <Link 
                                  to="/settings/table"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => {
                                    setIsOptionRight(true);
                                  }}
                                >
                                  Opciones
                                </Link>
                            }
                            {
                              values.right_panel == "FORM" &&
                                <Link
                                  to="/settings/form"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => {
                                    setIsOptionRight(true);
                                  }}
                                >
                                  Opciones
                                </Link>
                            }
                          </div>

                        </div>
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                          {getPanelIcon(values.right_panel)}
                        </div>
                      </div>
                    </div>
                  </div>

                </>

              }()
            }

            <button
              type="submit"
              class="btn btn-primary btn-block ml-2 mt-3"
            >
              Guardar
            </button>

          </Form>
        )}
      </Formik>
    </div>

    {isEditor ?
      <ModalRoute
        width={800}
        height={600}
        path="/settings/table"
        component={TableSettings}
        flow={flow}
        collections={collections}
        currentDefaults={execModeDefaults[formRef.current?.values?.reference]}
        initialValues={formRef.current?.values || {}}
        isOptionRight={isOptionRight}
        setParentFieldValue={formRef.current?.setFieldValue}
        getColumnOptions={getColumnOptions}
        onCreateTable={onCreateTable}
        onClose={onClose}
      /> : null}

    {isEditor ?
      <ModalRoute
        width={800}
        height={600}
        path="/settings/form"
        component={FormSettings}
        flow={flow}
        execModeDefaults={execModeDefaults}
        currentDefaults={execModeDefaults[formRef.current?.values?.reference]}
        isOptionRight={isOptionRight}
        initialValues={formRef.current?.values || {}}
        setParentFieldValue={formRef.current?.setFieldValue}
        onClose={onClose}
      /> : null}

  </div>
)

const getPanelIcon = (value) => {

  let classname = "fas fa-minus";
  if (value == "TABLE") classname = "fas fa-table";
  if (value == "ACTIVITY_CHART") classname = "fas fa-chart-area";
  if (value == "FORM") classname = "fas fa-file-alt";

  return <i className={classname} style={{ fontSize: "12rem", color: "#dfe3ee" }}></i>

}

export default SettingsView;