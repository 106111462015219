import React, { useEffect, useState } from "react";
import { usePost } from "seed/api";
import View from "components/util/schema_form/fields/dashboard/DashboardBuilder.view";


const defaultChart = {
  title: null,
  type: null,
  data: {
    x: {
      value: null,
      grouper: null
    },
    y: {
      value: null,
      grouper: null
    },
  }
}


function DashboardBuilder({
  name,
  label,
  required,
  disabled,
  caption,
  helpMessage,
  values,
  flowId,
  dashboardButtonText,
  setFieldValue,
}) {

  const [isEditDashboardShown, setIsEditDashboardShown] = useState(false);
  const [dashboardData, setDashboardData] = useState([])
  const initialDashboard = values[name];
  const [charts, setCharts] = useState(
    initialDashboard ? initialDashboard :
      Array.from({ length: 1 }, (_, i) => ({ ...defaultChart, id: i }))
  );

  const [callDashboardDataset, reqDashboardDataset] = usePost("/flows/get_temp_dashboard_data", {
    onCompleted: (data) => {
      const formattedData = charts.map((chart, idx) => {
        return {
          ...chart,
          dataset: data[idx]
        }
      })
      setDashboardData(formattedData);
    }
  });

  useEffect(() => {
    callDashboardDataset({
      x_features: charts.map(chart => chart.data.x.value),
      y_features: charts.map(chart => chart.data.y.value),
    })
  }, [charts])

  const onOpenDashboardModal = () => setIsEditDashboardShown(true);
  const onCloseDashboardModal = () => setIsEditDashboardShown(false);

  const onClickAddChart = () => {
    const newChart = { ...defaultChart, id: charts.length };
    setCharts(prevCharts => prevCharts.concat(newChart));
  }

  const onDeleteChart = (id) => {
    setCharts(prevCharts => prevCharts.filter(chart => chart.id !== id));
  }

  console.log("dashboardData", dashboardData)

  const modalProps = {
    name,
    label,
    required,
    caption,
    disabled,
    helpMessage,
    values,
    flowId,
    charts,
    dashboardButtonText,
    isEditDashboardShown,
    setFieldValue,
    setCharts,
    onClickAddChart,
    onDeleteChart,
  }

  return (
    <View
      dashboardData={dashboardData}
      charts={charts}
      name={name}
      label={label}
      required={required}
      caption={caption}
      disabled={disabled}
      helpMessage={helpMessage}
      values={values}
      initialDashboard={initialDashboard}
      modalProps={modalProps}
      dashboardButtonText={dashboardButtonText}
      isEditDashboardShown={isEditDashboardShown}
      setFieldValue={setFieldValue}
      setCharts={setCharts}
      onOpenDashboardModal={onOpenDashboardModal}
      onCloseDashboardModal={onCloseDashboardModal}
    />
  );
}


export default DashboardBuilder;