import React from "react";
import PropTypes from "prop-types";

const getChartImage = (type) => {
  if (type == "bar")
    return "/resources/images/bar-chart-icon.png";
  if (type == "line")
    return "/resources/images/line-chart-icon.png";
  if (type == "pie")
    return "/resources/images/pie-chart-icon.png";
  if (type == "scatter")
    return "/resources/images/scatter-chart-icon.png";
  if (type == "doughnut")
    return "/resources/images/doughnut-chart-icon.png";

  return "/resources/images/no-chart-icon.png";
}



const ChartView = ({
  id,
  type,
  title,
  isSelected,
  onClickChart,
  onClickDeleteChart
}) => (
  <div id={`chart-${id}`} className={`chart ${isSelected && "selected"}`} onClick={onClickChart}>

    <h4 className="text-center">{title}</h4>

    <div className="d-flex justify-content-center">
      <img className="img-fluid chart-icon" src={getChartImage(type)} alt="Chart icon" />
    </div>

    {!type && (
      <h5 className="text-center">Sin gráfico configurado</h5>
    )}

    <button className="btn btn-danger px-2 py-1" style={{
      position: "absolute",
      top: "0",
      right: "0",
    }} onClick={onClickDeleteChart}>
      <i className="fas fa-trash"></i>
    </button>

  </div>
);

ChartView.propTypes = {};

export default ChartView;