/* eslint-disable max-len */
import React from "react";
import { ModalRoute } from "seed/helpers";
import { BrowserRouter } from "react-router-dom";
import Actions from "components/board/toolbox/actions/Actions"
import Templates from "components/board/toolbox/templates/Templates"
import Ideas from "components/studio/ideas/Ideas"
import Collections from "components/collections/Collections";
import Apps from "components/board/toolbox/apps/Apps";
import SidenavItem from "components/board/toolbox/Toolbox.sub.SidenavItem.view";
import "styles/css/board/Toolbox.css";


const ToolboxView = ({
  toolboxRef,
  flowId,
  pageId,
  nodes,
  reqApps,
  reqAllApps,
  currentNodeContext,
  activeSection,
  isCollapsed,
  isAnonymous,
  isDisabled,
  onClickNode,
  onClickCollapseButton,
  onClickSidenavItem,
  reloadFlow
}) => (
  <BrowserRouter basename={`/app/flow/${flowId}/flow`}>
    <div id="toolbox-container" className={`d-flex h-100 ${isCollapsed ? "collapsed" : ""} ${activeSection}-section`}>

      <div id="toolbox-sidenav" className="d-flex flex-column scrollbar-narrow h-100"
        style={{ boxSizing: "border-box", overflowY: "auto" }}>

        <SidenavItem
          name="guides"
          label="Guías"
          icon="fas fa-graduation-cap"
          iconStyle={{ fontSize: "1.45rem" }}
          activeSection={!isCollapsed ? activeSection : null}
          onClickItem={onClickSidenavItem} />

        <SidenavItem
          name="actions"
          label="Acciones"
          icon="fas fa-shapes"
          activeSection={!isCollapsed ? activeSection : null}
          onClickItem={onClickSidenavItem} />

        <SidenavItem
          name="templates"
          label="Plantillas"
          icon="fas fa-th"
          activeSection={!isCollapsed ? activeSection : null}
          onClickItem={onClickSidenavItem} />

        <div style={{ height: "1px", background: "#ffffff50", margin: "0.8rem 0.8rem 0.9rem 0.8rem" }} />

        <SidenavItem
          name="data"
          label="Datos"
          icon="fas fa-table"
          activeSection={!isCollapsed ? activeSection : null}
          onClickItem={onClickSidenavItem} />

        <div style={{ height: "1px", background: "#ffffff50", margin: "0.8rem 0.8rem 0.9rem 0.8rem" }} />

        <SidenavItem
          name="ideas"
          label="Ideas"
          icon="fas fa-lightbulb"
          activeSection={!isCollapsed ? activeSection : null}
          onClickItem={onClickSidenavItem} />


      </div>

      {!isCollapsed ?
        <div id="toolbox-main" className="h-100">

          {activeSection == "guides" ?
            <div className="w-100 h-100 bg-white py-10"
              style={{ overflow: "hidden" }}>
              <img src={"/theme/img/under-construction.gif"} alt="En construcción"
                style={{ width: "150%", marginLeft: "-25%" }} />
            </div> : null}

          {activeSection == "actions" ?
            <Actions
              toolboxRef={toolboxRef}
              flowId={flowId}
              nodes={nodes}
              reqApps={reqApps}
              reqAllApps={reqAllApps}
              currentNodeContext={currentNodeContext}
              isAnonymous={isAnonymous}
              isDisabled={isDisabled}
              onClickNode={onClickNode}
            /> : null}

          {activeSection == "templates" ?
            <Templates
              flowId={flowId}
              pageId={pageId}
              reloadFlow={reloadFlow}
              currentNodeContext={currentNodeContext}
            /> : null}

          {activeSection == "data" ?
            <Collections
              flowId={flowId}
            /> : null}

          {activeSection == "ideas" ?
            <Ideas
              flowId={flowId}
              pageId={pageId}
              reloadFlow={reloadFlow}
            /> : null}

        </div>
        : null}

      <div id="collapse-toolbox" onClick={onClickCollapseButton}>
        {isCollapsed ?
          <i class="fas fa-angle-right"></i>
          : <i className="fas fa-angle-left"></i>
        }
      </div>

      <ModalRoute
        height="600"
        path={"/apps"}
        component={() =>
          <div className="card">
            <div class="card-header d-flex justify-content-between"><h3 class="mb-0">Acciones</h3></div>
            <Apps reqApps={reqApps} flowId={flowId} />
          </div>}
      />

    </div>
  </BrowserRouter>
);

export default ToolboxView;
