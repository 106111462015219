import React from "react";
import { Formik, Form, Field } from "formik";
import { FileField, Loading, Modal } from "seed/helpers";
import IdeasHistory from "components/studio/ideas/IdeasHistory";
import IdeasDetail from "components/studio/ideas/IdeasHistory.view.lib.detail";

const IdeasView = ({
  formikRef,
  flowId,
  pageId,
  options,
  suggestion,
  initialized,
  isLoading,
  isHistoryShown,
  setIsHistoryShown,
  onGeneratePositions,
  onGenerateProcesses,
  onGenerateProcess,
  onGenerateImageSolution,
  onGenerateSolution,
  onCreateGuide,
  onChangeStep,
  reloadFlow
}) => {
  return (
    <div
      id="suggestions"
      className="h-100 px-1 py-1 scrollbar-narrow"
      style={{
        position: "relative",
        zIndex: 5,
        overflowY: "auto",
      }}
    >

      {
        initialized
          ? null
          : <div className="d-flex flex-column align-items-center w-100 my-10">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
      }

      <Formik
        innerRef={formikRef}
        initialValues={{
          initials: true,
          step: 0,
          process_steps: [""]
        }}
      >
        {({ values, setFieldValue }) =>
          <Form>

            {values.initials ? null : onChangeStep(values)}

            <div className="card-body d-flex flex-column pt-4 px-4 h-100">

              {
                initialized &&
                <>
                  {
                    function () {

                      const components = [];
                      const steps = [
                        "Proceso",
                        "Detalle",
                        "Guía"
                      ];

                      for (let i = 0; i < steps.length; i++) {

                        components.push(
                          <div
                            key={"step" + i}
                            className="d-flex flex-column align-items-center"
                            onClick={i < values.step ? () => setFieldValue("step", i) : null}
                            style={{
                              cursor: i < values.step ? "pointer" : "default",
                              width: "42px"
                            }}
                          >

                            <span className="fa-stack">
                              <i
                                className="fas fa-circle fa-stack-2x"
                                style={{
                                  color: i == values.step ? "#2080e5" :
                                    i < values.step ? "#00c9a7" : "#a0a5aa"
                                }}
                              />
                              <span className="fa-stack-1x text-white"
                                style={{ fontWeight: i == values.step ? "bold" : "normal" }}>
                                {
                                  i < values.step
                                    ? <i className="fas fa-check fa-stack-1x"></i>
                                    : i + 1
                                }
                              </span>
                            </span>
                            <span className="mt-2"
                              style={{ fontSize: "0.84rem", fontWeight: i == values.step ? "bold" : "normal" }}>
                              {steps[i]}
                            </span>
                          </div>
                        );

                        if (i < steps.length - 1)
                          components.push(<hr key={"hr" + i} className="w-100 mt-3 mx-3" />);

                      }

                      return <div className="d-flex justify-content-between w-100 mb-5 px-2">
                        {components}
                      </div>

                    }()
                  }

                  {
                    values.step == 0 && <>
                      <div className="d-flex flex-column w-100">
                        <div className="mb-3 animate__animated animate__fadeIn">
                          <div className="h4">
                            Describe tu proceso
                          </div>
                          <Field
                            className="form-control scrollbar-narrow"
                            as="textarea"
                            rows={2}
                            style={{ resize: "none" }}
                            name="process_title"
                            placeholder="Ej. Conciliación bancaria"
                            onBlur={(e) =>
                              onGenerateProcess(values.process_title)}
                          />
                          {/* <FileField
                            name="image"
                            className="mt-3"
                            accept="image/*"
                            setFieldValue={setFieldValue}
                            onCompleted={onGenerateImageSolution}
                          /> */}
                        </div>
                        {
                          (options.processes && options.processes.length > 0) &&
                          <div className="animate__animated animate__fadeIn mt-3">
                            <p className="font-italic mb-2">
                              Ideas
                            </p>
                            <div className="d-flex flex-column w-100">
                              {
                                options.processes.map((process, index) => index < 5 &&
                                  <button
                                    type="button"
                                    key={"process" + index}
                                    className={"btn btn-lighter form-control mb-2 text-left h-auto"}
                                    style={{ border: ".0625rem solid #e7eaf3" }}
                                    onClick={() => {
                                      setFieldValue("process_title", process);
                                      onGenerateProcess(process);
                                    }}
                                  >
                                    {process}
                                  </button>
                                )
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </>
                  }

                  {
                    values.step == 1 && <>

                      <div className="d-flex flex-column w-100">
                        <div className="mb-3 animate__animated animate__fadeIn mb-2">
                          <div className="h4 mb-3">
                            Platícanos de tu proceso <br />
                            <small className="text-muted">Entre más específico, mejores resultados</small>
                          </div>
                          {
                            (values.process_steps).map((step, index) =>
                              <div key={"step" + index} className="d-flex flex-column align-items-start w-100 mb-3">
                                <span
                                  className="mr-2 h5"
                                  style={{
                                    userSelect: "none",
                                    pointerEvents: 'none',
                                    lineHeight: "1.35",
                                  }}
                                >
                                  Paso {index + 1}
                                </span>
                                <div className="d-flex align-items-center w-100">
                                  <Field
                                    className="form-control scrollbar-narrow"
                                    as="textarea"
                                    rows={3}
                                    style={{ resize: "none" }}
                                    name={"process_steps[" + index + "]"}
                                    placeholder="Descripción"
                                  />
                                  <span
                                    className="ml-3"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const steps = values.process_steps;
                                      steps.splice(index, 1);
                                      setFieldValue("process_steps", steps);
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </span>
                                </div>
                              </div>
                            )
                          }
                          <div
                            className="w-100"
                            style={{
                              paddingRight: "1.6rem"
                            }}>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary w-100"
                              onClick={() => {
                                const steps = values.process_steps;
                                steps.push("");
                                setFieldValue("process_steps", steps);
                              }}
                            >
                              <i className="fas fa-plus mr-1"></i> Agregar paso
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="animate__animated animate__fadeIn mt-3 w-100">
                        <p className="font-italic mb-2">
                          Ideas <br />
                          <small className="text-muted"
                            style={{ fontSize: "0.8rem", fontStyle: "normal" }}>
                            Selecciona los pasos que te gustaría incluir</small>
                        </p>

                        <div className="d-flex flex-wrap w-100">
                          {
                            options.processSteps ?
                              function () {
                                try {
                                  return <>
                                    {options.processSteps.map((step, index) => (
                                      <div
                                        key={`step-${index}`}
                                        className='bg-white animate__animated animate__fadeIn animate__faster w-100'
                                        style={{
                                          cursor: "pointer",
                                          border: "3px solid #F8FAFE"
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            const steps = values.process_steps;
                                            if (steps.length == 0)
                                              steps.push("")
                                            else if (steps[steps.length - 1] != "")
                                              steps.push("")
                                            steps[steps.length - 1] = step
                                            steps.push("");
                                            setFieldValue("process_steps", steps);
                                            setFieldValue("used_steps", [...(values.used_steps || []), step]);
                                          }}
                                          className="btn text-left px-3 w-100 d-flex align-items-center btn btn-lighter"
                                          style={{ border: ".0625rem solid #e7eaf3" }}
                                        >
                                          {index + 1}. {step}
                                        </a>
                                      </div>
                                    ))}
                                  </>
                                }
                                catch (e) {
                                  return <></>
                                }
                              }() : <Loading size={30} />
                          }
                        </div>
                      </div>
                    </>
                  }

                  {
                    values.step == 2 && <>
                      <div className="animated animate__fadeIn">
                        {
                          !suggestion?.response &&
                          <div className="d-flex w-100 justify-content-center mt-7">
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        }
                        {
                          suggestion?.response && <>
                            <IdeasDetail
                              suggestion={suggestion}
                              showRegenerateButton={true}
                              onRegenerate={onGenerateSolution}
                            />
                          </>
                        }
                      </div>
                    </>
                  }

                  <div className="d-flex flex-column w-100 mt-4">

                    {
                      function () {

                        let condition = false;
                        let type = "button";

                        if (values.step == 0) condition = values.process_title;
                        if (values.step == 1) condition = values.process_steps && values.process_steps.length > 0;
                        if (values.step == 2) condition = suggestion;
                        if (isLoading) condition = false;

                        return <button
                          type={type}
                          className={"btn w-100 mb-2 " + (values.step < 2 ? "btn-primary" : "btn-accent")}
                          disabled={!condition}
                          onClick={() => {

                            if (values.step == 1)
                              onGenerateSolution();

                            if (values.step < 2)
                              setFieldValue("step", values.step + 1)

                            if (values.step == 2 && suggestion?.response)
                              onCreateGuide();

                          }}
                        >
                          {values.step < 2 ? "Siguiente" : "Crear guía"}
                        </button>

                      }()
                    }

                    {
                      function () {
                        let type = "button";
                        if (values.step > 0)
                          return (
                            <button
                              type={type}
                              className="btn btn-lighter w-100"
                              onClick={() => setFieldValue("step", values.step - 1)}
                            >
                              Anterior
                            </button>
                          );
                      }()
                    }

                  </div>

                  {
                    values.step == 0 &&
                    <div className="d-flex mt-1">
                      <button
                        type="button"
                        className="btn btn-link text-muted p-0"
                        onClick={() => setIsHistoryShown(true)}
                      >
                        <i className="fas fa-history mr-2"></i>
                        Ver historial
                      </button>
                    </div>
                  }

                </>

              }


            </div>

          </Form>
        }
      </Formik>

      {isHistoryShown && (
        <Modal
          width={600}
          height={600}
          animation={""}
          flowId={flowId}
          pageId={pageId}
          reloadFlow={reloadFlow}
          onClose={() => setIsHistoryShown(false)}
          component={IdeasHistory}
        />
      )}
    </div>
  );
}

export default IdeasView;