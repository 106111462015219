
/* eslint-disable max-len */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { Route, Modal } from "seed/helpers"
import { APP_URL } from "settings";
import Board from "components/board/Board";
import Collections from "components/collections/Collections";
import Activity from "components/executions/Executions"
import Toolbox from "components/board/toolbox/Toolbox";
import Executions from "components/board/executions/Executions";
import RPADesigner from "components/tmisc/apps/rpa/designer/RPADesigner";
import Contact from "components/studio/forms/contact_us/Contact";
import Execution from "components/studio/forms/executions/Execution";
import ExecMode from "components/presentation/ExecMode";
import { postMessage } from "components/util/win";
import HelpMessage from 'components/util/helpers/HelpMessage';
import WSManager from "components/util/ws";

const ControllerView = ({
  flow,
  nodes,
  edges,
  user,
  selectedNodes,
  activePage,
  reactFlowWrapper,
  actualExecutionId,
  execModeRef,
  wsExecutionRef,
  wsNodeRef,
  toolboxRef,
  toolboxNodeContext,
  toolboxActiveSection,
  showAnimations,
  isFlowBeingSaved,
  isGeneratingFlow,
  isDeveloper,
  isAnonymous,
  isContactUsShown,
  isDesignerShow,
  isRPADesigner,
  isExecutionDetailsShown,
  isExecMode,
  isAnimationOptionShown,
  isPaginationShown,
  isToolboxCollapsed,
  isExecutionsCollapsed,
  setIsContactUsShown,
  setReactFlowInstance,
  setActivePage,
  setIsDesignerShow,
  setIsExecutionDetailsShown,
  setToolboxActiveSection,
  refetchExecutions,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickBack,
  onClickNode,
  onClickAnnotation,
  onClickUndo,
  onClickRedo,
  onDeleteSelectedNodes,
  onChangeExecMode,
  onExecutionMessage,
  onNodeMessage,
  onChangeAnimations,
  onGenerateFlow,
  onOpenToolbox,
  onCloseToolbox,
  onOpenExecutions,
  onCloseExecutions,
  reloadFlow,
}) => (
  <div id="flow">
    <div class="container-fluid p-0 h-100">

      <div class="d-flex h-100" style={{ overflowY: "auto" }}>

        <div id="workspace" class={"w-100"}>
          {!isRPADesigner && (
            <div id="workspace-header" className="d-flex justify-content-between align-items-center">

              <div class="d-flex align-items-center" id="workspace-title">

                <a
                  className="back-button-flow d-flex align-items-center justify-content-center"
                  title="Regresar"
                  onClick={onClickBack}>
                  <i class="fas fa-arrow-left d-block"></i>
                </a>

                <div class="w-auto">
                  <a href={!isAnonymous ? "/app" : "/use_cases"} >
                    <div className="logo"></div>
                  </a>
                </div>

                <div class="ml-1">

                  <div class="dropdown navbar-dropdown-account-wrapper rounded pr-2 my-0 py-0"
                    style={isAnonymous ? { background: "transparent" } : {}}>
                    <div class="dropdown-toggle ml-2" data-toggle={!isAnonymous ? "dropdown" : ""} aria-expanded="false">
                      <span class="d-flex h5 text-hover-primary mb-0">
                        <span className="flow-name d-inline-block text-truncate">
                          <span>{flow.name}</span>
                          <span class="d-block text-body">
                            {!isAnonymous ? `Plan ${(user?.license?.name ?? "básico").toLowerCase()}` : "Demo"}
                          </span>
                        </span>
                        <i className="fas fa-chevron-down mt-1"
                          style={{ color: "#888", marginLeft: "0.6rem", fontSize: "0.9em" }}></i>
                      </span>
                    </div>

                    <div class="dropdown-menu pr-1" style={{ minWidth: "12.5rem" }} aria-labelledby="dropdownFlowSettings">

                      <Link to="/flow/edit_flow" class="dropdown-item">
                        <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar proceso
                      </Link>

                      <Link to="/flow/share" className="dropdown-item">
                        <i class="fas fa-share dropdown-item-icon mr-1"></i> Compartir
                      </Link>

                      <hr className="my-2" />

                      <a href={`/app/settings/flows`} class="dropdown-item">
                        <i class="fas fa-list dropdown-item-icon mr-1"></i> Ver procesos
                      </a>

                      <a href={`/app/settings`} class="dropdown-item">
                        <i class="fas fa-cog dropdown-item-icon mr-1"></i> Ajustes
                      </a>

                      {isDeveloper && (
                        <div>
                          <hr className="my-2" />

                          <button
                            className="dropdown-item"
                            onClick={() => {
                              postMessage({ type: "open_capture", flow_id: flow.id, page_id: activePage });
                            }}>
                            <i class="fa-solid fa-mouse-pointer dropdown-item-icon mr-1"></i> RPA Debug
                          </button>
                        </div>
                      )}

                    </div>
                  </div>

                </div>
              </div>


              {/*!isExecMode && (
                <div id="flow-tabs">
                  <Tab
                    flow={flow}
                    route={"/flow"}
                    title={"Proceso"}
                    width={"7rem"}
                    caption={"Diseña tu proceso con las acciones que necesites"}
                    onClick={refetchExecutions}
                  />

                  {user.license?.name != "Test-ext" ?
                    <Tab
                      flow={flow}
                      route={"/data"}
                      title={"Datos"}
                      width={"5.5rem"}
                      caption={"Guarda información de tu proceso como datos de clientes, empleados o solicitudes"}
                    /> : null}

                  <Tab
                    flow={flow}
                    route={"/results"}
                    title={"Resultados"}
                    width={"7rem"}
                    caption={"Visualiza los resultados y detalles de tus procesos"}
                    badge={alertStatusColor && 
                      <i className={`fas fa-circle fa-sm d-inline-block text-${alertStatusColor}`}
                        style={{fontSize: "0.52rem", position: "relative", top: "-0.45rem", left: "-0.5rem"}}></i>}
                  />

                  <label className="flow-tab-padding"></label>
                </div>
              )*/}

              <div id="workspace-actions" className="d-flex">

                <span className="d-flex align-items-center">
                  {!isExecMode ?
                    <HelpMessage message="Comparte tu proceso con otras personas, permitiendo a otros colaborar"
                      style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                      <button className="btn btn-sm btn-outline-primary mr-2 share-button" onClick={onOpenExecutions}>
                        <i class="fas fa-play mr-1"></i> Ejecuciones
                      </button>
                    </HelpMessage> :
                    <HelpMessage message="Comparte tu proceso con otras personas, permitiendo a otros colaborar"
                      style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                      <Link to="/flow/share" className="btn btn-sm btn-outline-primary mr-2 share-button">
                        <i class="fas fa-share mr-1"></i> Compartir
                      </Link>
                    </HelpMessage>}

                  {user.license?.name != "Test-ext" ?
                    !isExecMode ?
                      <HelpMessage message="Visualiza tu proceso de forma gráfica"
                        style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                        <a href={`${APP_URL}/p/${flow.token}`} className="btn btn-sm btn-primary mr-1 px-3">
                          <i class="fas fa-eye mr-1"></i> Modo visual
                        </a>
                      </HelpMessage>
                      :
                      <HelpMessage message="Configura las acciones y flujo de tu proceso"
                        style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                        <button onClick={() => onChangeExecMode(!isExecMode)} className="btn btn-sm btn-primary mr-1 px-3">
                          <i class="fas fa-edit mr-1"></i> Modo edición
                        </button>
                      </HelpMessage>
                    : null}
                </span>

                {isAnonymous && (
                  <button id="contact-us-btn" className="btn btn-sm btn-accent px-3" onClick={() => setIsContactUsShown(true)}>
                    <i class="fa-solid fa-comment mr-1"></i> Contáctanos
                  </button>
                )}

              </div>
            </div>
          )}

          {isExecMode ? (
            <ExecMode
              ref={execModeRef}
              isExecMode={isExecMode}
              flow={flow}
              onChangeExecMode={onChangeExecMode}
            />
          ) : (

            <Switch>
              <Route path="/flow" render={(props) =>
                <div className="d-flex flex-row"
                  style={{ height: "calc(100% - 68.5px)" }}>

                  {user.license?.name != "Test-ext" ?
                    <Toolbox
                      toolboxRef={toolboxRef}
                      flowId={flow.id}
                      pageId={activePage}
                      nodes={nodes.filter((item) => item.type == "node")}
                      currentNodeContext={toolboxNodeContext}
                      activeSection={toolboxActiveSection}
                      isDisabled={isExecMode}
                      isAnonymous={isAnonymous}
                      isCollapsed={isToolboxCollapsed}
                      setActiveSection={setToolboxActiveSection}
                      onClickNode={onClickNode}
                      onOpenToolbox={onOpenToolbox}
                      onCloseToolbox={onCloseToolbox}
                      reloadFlow={reloadFlow}
                    /> : null}

                  <Board
                    {...props}
                    nodes={nodes}
                    edges={edges}
                    user={user}
                    flowId={flow.id}
                    toolboxRef={toolboxRef}
                    activePage={activePage}
                    selectedNodes={selectedNodes}
                    showAnimations={showAnimations}
                    isFlowBeingSaved={isFlowBeingSaved}
                    isGeneratingFlow={isGeneratingFlow}
                    isRPADesigner={isRPADesigner}
                    isDeveloper={isDeveloper}
                    isAnonymous={isAnonymous}
                    isAnimationOptionShown={isAnimationOptionShown}
                    isPaginationShown={isPaginationShown}
                    reactFlowWrapper={reactFlowWrapper}
                    setActivePage={setActivePage}
                    setReactFlowInstance={setReactFlowInstance}
                    onDropNode={onDropNode}
                    onDragNode={onDragNode}
                    onConnectEdge={onConnectEdge}
                    onUpdateEdge={onUpdateEdge}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onClickNode={onClickNode}
                    onClickAnnotation={onClickAnnotation}
                    onDeleteSelectedNodes={onDeleteSelectedNodes}
                    onClickUndo={onClickUndo}
                    onClickRedo={onClickRedo}
                    onChangeAnimations={onChangeAnimations}
                    onGenerateFlow={onGenerateFlow}
                  />

                  <Executions
                    flow={flow}
                    isExecutionsCollapsed={isExecutionsCollapsed}
                    onCloseExecutions={onCloseExecutions}
                  />
                </div>

              } />
              <Route path="/results" render={() =>
                <Activity
                  flow={flow}
                  refetchExecutions={refetchExecutions}
                />
              } />
              <Route path="/data" render={() =>
                <Collections
                  flowId={flow?.id}
                />
              } />
              <Redirect to={"/flow"} />
            </Switch>
          )}
        </div>

      </div>
    </div>

    {isDesignerShow && (
      <Modal
        width={800}
        height={600}
        animation={""}
        onClose={() => setIsDesignerShow(false)}
        component={RPADesigner}
      />
    )}

    {isContactUsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsContactUsShown(false)}
        component={Contact}
      />
    )}

    {isExecutionDetailsShown && (
      <Modal
        width={800}
        height={800}
        animation={""}
        executionId={actualExecutionId}
        isShown={true}
        isButtonHidden={true}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={Execution}
      />
    )}

    <WSManager
      ref={wsExecutionRef}
      onMessageReceived={onExecutionMessage}
    />

    <WSManager
      ref={wsNodeRef}
      onMessageReceived={onNodeMessage}
    />

  </div>
);

export default ControllerView;