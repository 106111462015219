import React from "react";
import ExecutionDetails from "components/executions/ExecutionDetails";
import ExecutionSteps from "components/executions/ExecutionSteps";

const ExecutionsListView = ({
  randomKey,
  executions,
  totalPages,
  pageNum,
  placeholder,
  executionDetail,
  isColumns,
  isLoadingDetail,
  setPageNum,
  onSelectExecution,
  onResumeExecution,
  onCancelExecution
}) => (
  <div id="executions-list">

    <div className="row">
      <div className={isColumns ? "col-4" : "col-12"} id="executionsAccordion">
        {executions.map((execution, i) => (
          <div key={execution.id}>
            <ExecutionDetails
              idx={i}
              key={execution.id}
              execution={execution}
              isShown={i == 0}
              isColumns={isColumns}
              isActive={execution.id == executionDetail?.id}
              isButtonHidden={isColumns}
              onSelectExecution={onSelectExecution}
              onResumeExecution={onResumeExecution}
              onCancelExecution={onCancelExecution}
            />
          </div>
        ))}
      </div>
      <div 
        className={`${isColumns ? "col-8" : "d-none"} scrollbar-narrow`}
        style={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {
          isLoadingDetail &&
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
        }
        {
          executionDetail && !isLoadingDetail &&
            <ExecutionSteps 
              idx={0} 
              randomKey={randomKey} 
              details={executionDetail} 
            />
        }
      </div>
    </div>

    {totalPages > 1 && (
      <nav class="mt-3 float-right">
        <ul class="pagination d-flex align-items-center">

          <li
            onClick={() => setPageNum(pageNum - 1)}
            class="page-item"
            style={{ display: pageNum > 1 ? "block" : "none" }}
          >
            <a class="page-link" aria-label="Prev">
              <span aria-hidden="true">«</span><span class="sr-only">Prev</span>
            </a>
          </li>

          {
            Array(totalPages).fill(0).map((ignore, idx) =>
              <li
                key={idx}
                onClick={() => setPageNum(idx + 1)}
                class={"page-item " + (idx == pageNum - 1 ? "page-active" : "")}
              >
                <a
                  class={`btn ${idx == pageNum - 1 ? "btn-primary" : "btn-soft-secondary"} px-3 py-2`}
                  role="button"
                >
                  {idx + 1}
                </a>
              </li>
            )
          }

          <li
            onClick={() => setPageNum(pageNum + 1)}
            class="page-item"
            style={{ display: pageNum <= totalPages - 1 ? "block" : "none" }}
          >
            <a class="page-link" aria-label="Sig">
              <span aria-hidden="true">»</span><span class="sr-only">Sig</span>
            </a>
          </li>

        </ul>
      </nav>
    )}

    {executions.length === 0 && (
      <div className="h-100 no-data-legend">
        <div className="d-flex justify-content-start align-items-center h-100 ml-1">
          <span>{placeholder}</span>
        </div>
      </div>
    )}
  </div>
);

ExecutionsListView.propTypes = {};

export default ExecutionsListView;