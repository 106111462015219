import React from "react";
import { APP_URL } from "settings";
import { Loading } from "seed/helpers";


const TemplatesView = ({
  ideas,
  isNodeIdeasLoading,
  isUseCaseLoading,
  onClickUseCase
}) => (
  <div className="h-100">
    <div id="toolbox-ideas" className="w-100 scrollbar-narrow d-flex flex-column">

      <div className="w-100 section-title pr-1 mt-0">
        <label className="w-100 text-left">
          Ideas
        </label>
      </div>

      <div className="row">
        {(isNodeIdeasLoading || isUseCaseLoading) ? <Loading /> : (
          <>
            {ideas.map((idea, i) => (
              <div key={i} className="btn col-md-12 btn-outline-secondary flow-sample mb-3">
                <div className="pb-0 mb-0 action-title">
                  {idea?.flow?.name}
                </div>
                <div className="action-subtitle">
                  {idea?.flow?.description}
                </div>

                <div className="my-2">
                  <iframe
                    className="w-100 flow-preview"
                    src={`${APP_URL}/flow_preview/${idea?.flow?.id}`}
                    referrerpolicy="same-origin" title="Studio Preview"
                  />
                </div>

                <button className="btn btn-block btn-primary py-1" onClick={() => onClickUseCase(idea)}>
                  <i class="fas fa-magic mr-1"></i> Usar
                </button>
              </div>
            ))}
          </>
        )}

        {ideas.length == 0 && (
          <div className="col-md-12 text-center mt-4">
            <img src="/theme/svg/components/oc-browse.svg" alt="Not idea found" style={{ width: "80%" }} className="mb-1"/>
            <p>No hay plantillas disponibles</p>
          </div>
        )}
      </div>
    </div>

  </div>
)

export default TemplatesView;