
import React from 'react';
import { getDate } from "components/collections/util/format";

// eslint-disable-next-line
const executionQueryRegex = /[@#]{1}(?<name>[\w\d\s,]+)(\((?<filters>[\w\d=\s,"()'<>_#.@-]*)\))?(\[(?<index>[\d\w:"']*)\])?(?<queries>[\w\d_.]*)?(?<extra><[\w\W}]+>)?/;

const getObjectComponent = (value) => {

  // if value is a list, return a list with the list data
  if(value instanceof Array) {
    return <div>
      {
        value.map((item, i) => (
          <div key={i} className='mb-3'>
            {getObjectComponent(item)}
          </div>
        ))
      }
    </div>
  }
  
  // if value is an object, return a table with the object data

  if(value instanceof Object) {
    if(Object.keys(value).length === 1) return getObjectComponent(value[Object.keys(value)[0]]);
    return <table className="table-sm table-bordered w-100">
      <thead className='bg-light'>
        <tr>
          <th className='w-25'>Llave</th>
          <th className='w-75'>Valor</th>
        </tr>
      </thead>
      <tbody>
        {
          Object?.keys(value).map((key, i) => (
            <tr key={key}>
              <td>{key}</td>
              <td className='text-break'>
                {getObjectComponent(value[key])}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  }

  // if value is boolean or matches with True or False, return a boolean Si/No
  if(value == true || value == false 
    || ["true", "false"].includes(value?.toString()?.toLowerCase()))
      return value == true || value?.toString()?.toLowerCase() == "true" ? "Si" : "No";

  // if value is a date, return the date in format DD/MM/YYYY
  if(value instanceof Date) return getDate(value, "DD/MM/YYYY");

  // if value is a number, return the number
  if(!isNaN(value)) return value;

  // if value is a string, return the string
  const auxDate = getDate(value, "DD/MM/YYYY");
  if(auxDate) return auxDate;

  const isUrl = value?.toString()?.toLowerCase()?.includes("http");
  if(isUrl) return <a href={value} target="_blank" rel='noreferrer'>{value}</a>;

  return <div className='text-break'>
    {value}
  </div>
  
}

const tableDataToString = (query, options) => {

  const match = query.match(executionQueryRegex);

  if(match) {

    const extraMatch = match.groups.extra;
    if(extraMatch) 
      return query.replace(extraMatch, `<${JSON.stringify(options)}>`);

  } 

  return query + `<${JSON.stringify(options)}>`;
  
}

const stringToTableData = (query) => {

  const match = query.match(executionQueryRegex);

  if(match && match.groups.extra)
    return JSON.parse(match.groups.extra.slice(1, -1));

  return {};

}

export {
  getObjectComponent,
  tableDataToString,
  stringToTableData
};