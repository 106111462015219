import React from "react";
import ReactApexChart from 'react-apexcharts'


const DonutChartView = ({ options, series, height }) => (
  <div>
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" height={height} />
    </div>
    <div id="html-dist"></div>
  </div>
);


export default DonutChartView;