
import React, { useState, useEffect, useMemo } from 'react';
import ExcelJS from "exceljs";
import { usePost } from "seed/api";
import { useQuery, useDetail } from "seed/gql";
import ExecModeCards from 'components/presentation/panels/Data.lib.cards';
import ExecModeTable from 'components/presentation/panels/Data.lib.table';
import ExecModeList from 'components/presentation/panels/Data.lib.list';
import { parseExportData } from "components/collections/util/datatypes";
import { downloadFile } from "components/util/file_util";
import { formatNumber } from "components/collections/util/format";
import { addPostMessageListener, removePostMessageListener } from "components/util/flow";

const ExecModeData = ({ 
	flow, 
	isFullWidth,
	isRightPanel,
  execModeSetting,
}) => {
	
	const pageSize = 10;
	const [initialized, setInitialized] = useState(false);
	const [activeCollectionId, setActiveCollectionId] = useState(null);
	const [filters, setFilters] = useState([]);
	const [query, setQuery] = useState("");
	const [search, setSearch] = useState(sessionStorage.getItem("collection_search") || "");
	const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem("collection_page")) || 1);
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	const availableCollections = useMemo(() => 
		(execModeSetting.tables??[]).filter(t => isFullWidth || t.is_right == null || t.is_right == isRightPanel), 
			[execModeSetting.tables, isFullWidth, isRightPanel]);

  const activeSettings = useMemo(() => availableCollections.find(t => t.id == activeCollectionId) || {}, 
		[availableCollections, activeCollectionId]);

	const collectionFilter = columns.filter(column => column.type === "collection")
		.map(column => `(id = ${column?.structure?.collection_id})`)
		.join(" OR ");

	const indexedSettings = availableCollections.reduce((acc, curr) => {
		acc[curr.id] = curr;
		return acc;
	}, {});

	const [callGetAllData, reqGetAllData] = usePost("/collections/get_data", {
		onCompleted: (rowsData) => {

			const sheetName = "Sheet1";
			const workbook = new ExcelJS.Workbook();
			const sheet = workbook.addWorksheet(sheetName);

			const excelColumns = [
				...columns.map((c) => c.label),
				"ID Sistema",
				"Folio Sistema"
			];

			const excelRows = rowsData.data.map((row) => [
				...columns.map((c) => parseExportData(c, row.data[c.name])),
				row.id,
				row.full_folio
			]);

			sheet.addRow(excelColumns);

			const lastRow = sheet.lastRow;
			for (let i = 0; i < columns.length + 2; i++) {
				if (i < columns.length)
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
				else
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
			}

			excelRows.forEach((row) => sheet.addRow(row));

			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], { type: "application/octet-stream" });
				downloadFile(URL.createObjectURL(blob), null, "export.xlsx");
			});

		}
	});

	const qCollectionData = useQuery(`{
		collectionColumns: collections {
			id
			name
			schema
			initials
			folios
			collectionDataes {
				id
				data
				folio
			}
		}
	}`, collectionFilter.length > 0 ? `(${collectionFilter})` : "null");

  const qCollection = useDetail(`{
		collection {
			id
			schema
			name
			initials
		}
	}`, activeCollectionId);

  const qCollections = useQuery(`{
		collections {
			id
			name
			initials
		}
	}`, "flowId=" + flow.id, {
		onCompleted: (data) => {

			const collections = data.collections.filter(c => availableCollections.length === 0 ||
				availableCollections.map(c => c.id).includes(c.id));

			if(collections.length > 0 && !activeCollectionId) {

				let defCollectionId = collections[0].id

				for (let collection of collections)
					if (collection.id == sessionStorage.getItem("def_collection_" + flow.id))
						defCollectionId = collection.id

				setActiveCollectionId(defCollectionId)

			}

		}
	});

  const [callGetData, reqGetData] = usePost("/collections/get_data");

  const refreshData = () => callGetData({
		collection_id: activeCollectionId,
		page_size: pageSize,
		page_number: pageNum,
		ignore_compress: true,
		search,
		query
	});

	const onChangeSearch = (value) => {
		sessionStorage.setItem("collection_search", value)
		setSearch(value);
	}

	const onChangeFilters = (value) => {
		sessionStorage.setItem("collection_filters", JSON.stringify(value))
		setFilters(value);
	}

	const onChangeQuery = (value) => {
		sessionStorage.setItem("collection_query", value)
		setQuery(value);
	}

  const onPageChange = (page) => {
		if (page < 1) page = 1;
		if (page > totalPages) page = totalPages;
		sessionStorage.setItem("collection_page", page)
		sessionStorage.setItem("collection_total_pages", totalPages)
		setPageNum(page);
	}

	const onClickTab = (collectionId) => {
		sessionStorage.setItem("def_collection_" + flow.id, collectionId)
		sessionStorage.removeItem("collection_search")
		sessionStorage.removeItem("collection_query")
		sessionStorage.removeItem("collection_filters")
		sessionStorage.removeItem("collection_page")
		sessionStorage.removeItem("collection_total_pages")
		setActiveCollectionId(collectionId);
	}

	const onExport = (extension) => {
		callGetAllData({
			collection_id: activeCollectionId,
			ignore_compress: true
		});
	}

	const { collectionColumns = [] } = qCollectionData.data || {};
	const collectionColumnsAux = collectionColumns.map(c => ({
		...c,
		collectionDataes: c.collectionDataes.map(d => ({
			...d,
			_folio: `${c.initials}-${formatNumber(d.folio)}`
		}))
	}));

  const { collection } = qCollection.data || {};
  const { collections: auxCollections = [] } = qCollections.data || {};
	
  const collections = auxCollections.filter(c => availableCollections.length === 0 || 
		availableCollections.map(c => c.id).includes(c.id));

	collections.sort((a, b) => {
		const aIndex = availableCollections.findIndex(c => c.id == a.id);
		const bIndex = availableCollections.findIndex(c => c.id == b.id);
		return aIndex - bIndex;
	});

  const collectionDataes = (reqGetData.data || {}).data;
  const totalPages = (reqGetData.data || {}).total_pages || 0;

  useEffect(() => {
		setRows([]);
		qCollection.refetch();
		refreshData();
	}, [pageNum, activeCollectionId, search, query, filters]);

  useEffect(() => {

		if(!collection) return;

		const sortedData = Object.values(collectionDataes || []);
		sortedData.sort((a, b) => a.folio - b.folio);

		const data = sortedData.map(d => ({
			data: function () {
				try { return JSON.parse(d.data) ?? {} }
				catch (e) { return (d.data) ?? {} }
			}(),
		}));

		setRows(data);

	}, [collectionDataes, collection]);

  useEffect(() => {
		if(collection && collection.schema) {
			let schema = {};
			try { schema = JSON.parse(collection.schema) ?? {} }
			catch (e) { schema = (collection.schema) ?? {} }
      schema = Object.assign({}, schema);
      schema.structure = schema.structure.filter(c => activeSettings?.columns?.includes(c.name));
			setColumns(schema.structure || []);
		}
	}, [collection, activeSettings]);

	useEffect(() => {

		if(initialized || Object.keys(activeSettings).length == 0) return;

		let initialFilters = JSON.parse(sessionStorage.getItem("collection_filters")) || [];
		if(activeSettings.filters && activeSettings.filters.length > 0)
			initialFilters = activeSettings.filters;

		let initialQuery = sessionStorage.getItem("collection_query") || activeSettings.query || "";
		if(activeSettings.query && activeSettings.query.length > 0)
			initialQuery = activeSettings.query;

		setFilters(initialFilters);
		setQuery(initialQuery);
		setInitialized(true);

	}, [activeSettings]);

	useEffect(() => {
		const onPostMessage = (data) => 
			data.type == "refetch-exec-mode-data" ? refreshData() : null;
    addPostMessageListener(onPostMessage);
		return () => removePostMessageListener(onPostMessage);
  }, [search, query, filters, pageNum, activeCollectionId]);

	return <div className="scrollbar-narrow"
		style={{ 
			height: "100%",
			overflowY: "auto",
		}}
	>

		<div className="w-100 h-100" style={{ paddingTop: "0.3rem" }}>
			<div className="w-100 p-4">

				{
					availableCollections.length > 1 &&
						<div id="tables" className="mt-n1">
							<div className="w-100" id="tables-header">
								<div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pb-2 mb-3"
									style={{ overflowX: "auto" }}>
									{(collections ?? []).map((collection, index) => (
										<a
											key={index}
											style={{
												maxWidth: "16rem",
											}}
											className={"btn collection-tab d-flex align-items-center mr-2 px-3 py-0 " +
												(activeCollectionId == collection.id ? "active" : "")}
										>
											<span 
												className="text-truncate py-2" 
												onClick={() => onClickTab(collection.id)}
											>
												{indexedSettings[collection.id]?.title ?? collection.name}
											</span>
										</a>
									))}
								</div>
							</div>
						</div>
				}

				<h3 className='mb-2 p-0 m-0 mr-1'>
					{indexedSettings[activeCollectionId]?.title ?? collection?.name}
				</h3>

				{
					activeSettings.view == "TABLE" &&
					<div style={{marginTop: "-2.1rem" }}>
						<ExecModeTable
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							query={query}
							filters={filters}
							search={search}
							showForm={activeSettings.is_editable}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
							onExport={onExport}
							onChangeSearch={onChangeSearch}
							onChangeFilters={onChangeFilters}
							onChangeQuery={onChangeQuery}
						/>
					</div>
				}

				{
					activeSettings.view == "LIST" &&
						<ExecModeList
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
						/>
				}

				{
					activeSettings.view == "CARDS" &&
						<ExecModeCards
							collections={collectionColumnsAux}
							values={rows}
							structure={columns}
							pageNum={pageNum}
							totalPages={totalPages}
							onClickTab={onClickTab}
							onPageChange={onPageChange}
						/>
				}

				{
					activeSettings.view != "TABLE" &&
						<div className="w-100 px-3 m-0">
							<nav class="mt-2 scrollbar-narrow" style={{ overflowX: "auto" }}>
								<ul class="pagination d-flex align-items-center mb-1">

									<li
										onClick={() => onPageChange(pageNum - 1)}
										class="page-item"
										style={{ display: pageNum > 1 ? "block" : "none" }}
									>
										<a class="page-link" aria-label="Prev">
											<span aria-hidden="true">«</span><span class="sr-only">Prev</span>
										</a>
									</li>

									{
										totalPages > 1 ?
										Array(totalPages).fill(0).map((_, idx) =>
											<li
												key={idx}
												onClick={() => onPageChange(idx + 1)}
												class={"page-item m-0 mr-2 " + (idx == pageNum - 1 ? "page-active" : "")}
											>
												<a
													class={`btn btn-sm ${idx == pageNum - 1 ? "btn-primary" : "btn-soft-secondary"} px-3`}
													role="button"
												>
													{idx + 1}
												</a>
											</li>
										) : null
									}

									<li
										onClick={() => onPageChange(pageNum + 1)}
										class="page-item"
										style={{ display: pageNum <= totalPages - 1 ? "block" : "none" }}
									>
										<a class="page-link" aria-label="Sig">
											<span aria-hidden="true">»</span><span class="sr-only">Sig</span>
										</a>
									</li>

								</ul>
							</nav>
						</div>
				}

			</div>
		</div>

	</div>

}

export default ExecModeData;