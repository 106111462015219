import React from "react";
import View from "components/executions/chart/Chart.view";


function Chart({ type, dataset }) {

  let series = [];
  let labels = Object.keys(dataset);

  if (type == "bar" || type == "line") {
    series = [{
      data: Object.values(dataset)
    }]
  } else if (type == "pie" || type == "doughnut") {
    series = Object.values(dataset);
  }

  return (
    <View
      type={type}
      labels={labels}
      series={series}
    />
  );
}

export default Chart;