/* eslint-disable max-len */
import React from "react";
import { ReactFlowProvider } from "reactflow";
import { BrowserRouter } from "react-router-dom";
import { ModalRoute } from "seed/helpers"
import Tour from "components/util/tour/Tour"
import Forms from "components/tmisc/apps/forms/Forms";
import RPA from "components/tmisc/apps/rpa/RPA";
import Controller from "components/studio/Controller";
import CreateNodeForm from "components/board/node_form/CreateNodeForm";
import EditNodeForm from "components/board/node_form/EditNodeForm";
import EditFormAnnotation from "components/board/node_form/EditFormAnnotation";
import FormSetFlow from "components/start/flows/FormSetFlow";
import FormSavePage from "components/studio/forms/pages/FormSavePage";
import FormSetPage from "components/studio/forms/pages/FormSetPage";
import Executions from "components/executions/Executions";
import SetupApp from "components/board/toolbox/apps/setup/SetupApp";
import Collaboration from "components/studio/forms/collaboration/Collaboration";
import Settings from "components/presentation/Settings";
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";
import Ideas from "components/studio/ideas/Ideas"
import IdeasReport from "components/studio/ideas/IdeasReport";
import "styles/css/studio/Studio.css";

const StudioView = ({
  flow,
  user,
  nodes,
  edges,
  annotations,
  actions,
  activePage,
  tutorialSteps,
  currentTutorialStep,
  history,
  historyPosition,
  isAnonymous,
  isDeveloper,
  isTutorialShown,
  execModeRef,
  controllerRef,
  setActivePage,
  setHistoryPosition,
  reloadFlow,
  onUpdateHistory,
  onCloseModalController
}) =>
  <div className="content container-fluid p-0">
    <div className="d-flex justify-content-center">
      <ReactFlowProvider>
        <BrowserRouter basename={`/app/flow/${flow.id}`}>
          
          <Tour
            steps={tutorialSteps}
            startAt={currentTutorialStep}
            show={isTutorialShown}
            tutorialName={"flow"}
          >
            <Controller
              ref={controllerRef}
              execModeRef={execModeRef}
              flow={flow}
              user={user}
              activePage={activePage}
              initialNodes={[...nodes, ...annotations]}
              initialEdges={edges}
              actions={actions}
              history={history}
              historyPosition={historyPosition}
              isAnonymous={isAnonymous}
              setActivePage={setActivePage}
              setHistoryPosition={setHistoryPosition}
              reloadFlow={reloadFlow}
              onUpdateHistory={onUpdateHistory}
            />
          </Tour>

          <ModalRoute
            width="600"
            height="800"
            path="/flow/suggestions"
            flowId={flow.id}
            pageId={activePage}
            reloadFlow={reloadFlow}
            component={() => <div className="card"><Ideas /></div>}
          />

          <ModalRoute
            width="1200"
            height="800"
            path="/flow/suggestions/report"
            isDeveloper={isDeveloper}
            component={IdeasReport}
          />

          <ModalRoute
            width="500"
            height="500"
            path="/flow/edit_flow"
            component={() =>
              <FormSetFlow
                match={{ params: { "flowId": flow.id } }}
              />
            }
          />
          
          <ModalRoute
            width="400"
            height="400"
            path="/flow/create_page"
            onClose={onCloseModalController}
            component={() => <FormSavePage match={{ params: { "flowId": flow.id } }} />}
          />

          <ModalRoute
            width="420"
            height="400"
            path="/flow/edit_page/:pageId"
            onClose={onCloseModalController}
            component={(props) => <FormSetPage match={{ params: { ...props.match?.params, "flowId": flow.id } }} />}
          />

          <ModalRoute
            width={800}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/create/:actionId"
            onClose={onCloseModalController}
            component={(props) =>
              <CreateNodeForm
                match={props.match}
                flowId={flow.id}
                activePageId={activePage}
                onSubmit={controllerRef.current?.onCreateNode}
              />
            }
          />

          <ModalRoute
            width={800}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/edit/:nodeId"
            onClose={onCloseModalController}
            component={(props) =>
              <EditNodeForm
                match={props.match}
                flowId={flow.id}
                activePageId={activePage}
                onSubmit={controllerRef.current?.onEditNode}
              />
            }
          />

          <ModalRoute
            width={800}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/create_annotation/:actionId"
            onClose={onCloseModalController}
            component={(props) =>
              <CreateNodeForm
                match={props.match}
                flowId={flow.id}
                activePageId={activePage}
                onSubmit={controllerRef.current?.onCreateAnnotation}
              />
            }
          />

          <ModalRoute
            width={800}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/edit_annotation/:annotationId"
            onClose={onCloseModalController}
            component={(props) =>
              <EditFormAnnotation
                match={props.match}
                flowId={flow.id}
                activePageId={activePage}
                onSubmit={controllerRef.current?.onEditAnnotation}
              />
            }
          />

          <ModalRoute
            width={600}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/setup/:appId"
            component={(props) =>
              <SetupApp
                match={props.match}
                flowId={flow.id} />}
          />

          <ModalRoute
            width={650}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/share"
            component={() => <Collaboration flowId={flow.id} />}
          />

          <ModalRoute
            width={750}
            height={window.innerHeight - 150}
            animation={""}
            path="/flow/website/:url"
            component={({ match }) => <div className="card w-100 h-100">
              <div className="w-100 text-center py-2">Liga para compartir:&nbsp;
                <a href={decodeURIComponent(match.params?.url)} target="_blank" rel="noreferrer">{decodeURIComponent(match.params?.url)}</a>
                <CopyClipboardButton link={decodeURIComponent(match.params?.url)} />
              </div>
              <iframe className="w-100 h-100"
                src={decodeURIComponent(match.params?.url)}
                referrerpolicy="same-origin" title="website" />
            </div>}
          />

          <ModalRoute
            width={1000}
            height={window.innerHeight - 150}
            animation={""}
            path="/exec_mode/settings/:tab"
            flow={flow}
            execModeRef={execModeRef}
            onClose={onCloseModalController}
            component={Settings}
          />
        </BrowserRouter>
      </ReactFlowProvider>
      <Forms flow={flow} />
      <RPA />
    </div>
  </div>;

export default StudioView;